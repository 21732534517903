.event__card {
  padding: 0 30px;
  margin: 0 30px;
  margin-bottom: 96px;
}

.event__headline {
  margin-bottom: 10px;
  text-align: center;
}

.event__subheading {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}

.event__desc {
  margin: 20px 0;
  text-align: justify;
  line-height: 1.5;
  margin: auto;
}

.event__images {
  display: grid;
  grid-template-columns: auto auto;
  place-content: center;
  column-gap: 30px;
  margin-bottom: 20px;
}

.event__card___title {
  font-size: 24px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.event__mentors {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  margin-bottom: 20px;
}

.guest__artists {
  display: grid;
  grid-template-columns: 242px 242px 242px 242px;
  column-gap: 20px;
  place-content: center;
  row-gap: 20px;
  margin-bottom: 20px;
}

.event__images .card {
  box-shadow: none;
  padding: 0;
}

.event__images .card img {
  border-radius: 20px;
}

.guest_artist_card h2 {
  text-align: center;
  font-size: 14px;
}

.event__mentors {
  width: 500px;
  margin: auto;
}

.event__mentors img,
.guest__artists img {
  height: 200px;
  width: 200px;
  align-self: center;
  border-radius: 10px;
}

.guest_artist_card .card {
  padding-bottom: 10px;
}

@media (max-width: 1200px) {
  .guest__artists {
    grid-template-columns: 242px 242px;
  }
}

@media (max-width: 992px) {
  .event__images {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
  .event__images .card img {
    height: auto;
  }
  .guest__artists {
    row-gap: 20px;
  }
}

@media (max-width: 762px) {
  .event__mentors {
    width: 100%;
  }
}

@media (max-width: 650px) {
  .guest__artists {
    grid-template-columns: 250px;
  }
  .event__mentors {
    display: grid;
    place-content: center;
    row-gap: 20px;
    grid-template-columns: 250px;
  }
}

@media (max-width: 480px) {
  .event__card {
    padding: 0;
    margin-bottom: 50px;
  }
}
