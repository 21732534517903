.news__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 40px 40px 40px;
  column-gap: 30px;
  row-gap: 30px;
}

.headline {
  text-align: center;
  padding: 10px;
  font-size: 26px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

@media (max-width: 1400px) {
  .news__container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .news__container {
    grid-template-columns: 1fr;
    column-gap: 0;
  }
}

@media (max-width: 576px) {
  .news__container {
    padding: 0 20px 20px 20px;
  }
}
