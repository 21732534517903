.events__container {
  padding: 20px 40px;
}

.events__section h2 {
  text-align: center;
  padding: 10px;
  font-size: 26px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.events__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 40px;
}

.upcoming_events__card {
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
}

.upcoming_events__card:hover {
  background-color: #fff3bf;
}

.event__card___content {
  padding: 20px 20px 20px 20px;
}

.upcoming_events__card img {
  width: 400px;
  height: 400px;
}

.zoom__details {
  display: flex;
  gap: 40px;
  align-items: center;
  font-weight: 600;
}

.zoomLink__btn {
  text-decoration: none;
  color: #fff;
  background-color: #222;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.upcoming_events__card h2 {
  font-size: 22px;
  margin: 0;
}

.upcoming_events__card p {
  font-size: 18px;
  text-align: justify;
}

@media (max-width: 1280px) {
  .zoom__details {
    gap: 20px;
  }
  .upcoming_events__card h2 {
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  .zoom__details {
    flex-wrap: wrap;
  }
}

@media (max-width: 992px) {
  .upcoming_events__card img {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 768px) {
  .events__container {
    grid-template-columns: 1fr;
    margin-bottom: 20px;
  }

  .events__container {
    padding: 20px;
  }

  .upcoming_events__card img {
    margin-bottom: 10px;
  }

  .upcoming_events__card h2 {
    font-size: 16px;
  }

  .upcoming_events__card p {
    font-size: 14px;
  }
}

@media (max-width: 400px) {
  .upcoming_events__card img {
    height: auto;
    width: 100%;
  }
}
