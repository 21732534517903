.members__section {
  padding: 20px 40px;
}

.members__section h2 {
  font-size: 28px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 400;
}

.members__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 40px;
  row-gap: 40px;
}

.members__container .card img {
  border-radius: 10px;
  height: 100px;
  width: 100px;
  align-self: center;
}

.members__container .card h2 {
  font-size: 16px;
  margin-bottom: 0px;
}

.members__container .card .card__desc {
  font-weight: 600 !important;
}

@media (max-width: 1280px) {
  .members__container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 992px) {
  .members__container {
    grid-template-columns: 1fr 1fr;
  }
  .members__container .card img {
    height: auto;
  }
}

@media (max-width: 768px) {
  .members__section {
    padding: 20px;
  }
}

@media (max-width: 578px) {
  .members__container {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }

  .members__container .card {
    gap: 4px;
    padding: 10px;
  }

  .members__container .card img {
    height: 80px;
    width: 80px;
    align-self: center;
  }
}
