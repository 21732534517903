.card {
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: auto;
  transition: all 0.3s;
}

.card:hover {
  background-color: #fff3bf;
}

.card img {
  height: 400px;
  width: 100%;
}

.card h3 {
  font-size: 16px;
  text-align: center;
}

.card p {
  text-align: justify;
  font-size: 14px;
  font-weight: 400;
  font-family: "Quicksand", sans-serif;
}

.card a {
  text-decoration: none;
  color: #fff;
  background-image: linear-gradient(
    108.7deg,
    rgba(221, 22, 224, 1) 11%,
    rgba(111, 22, 190, 1) 88.2%
  );
  padding: 0.5rem 2rem;
  border-radius: 20px;
  font-weight: 600;
  width: 150px;
}
