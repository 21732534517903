.founders__section {
  padding: 20px 40px;
}

.founders__section h2 {
  font-size: 28px;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 400;
}

.founder__container {
  display: grid;
  grid-template-columns: 400px 400px;
  column-gap: 30px;
  place-content: center;
}

.founders__row {
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 20px;
  gap: 40px;
  transition: all 0.3s;
}
.founders__row:hover {
  background-color: #fff3bf;
}

.founders__row:last-child {
  margin-bottom: 0;
}

.founders__img {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.founders__img img {
  height: 150px;
  width: 150px;
  border-radius: 10px;
}

.founders__img span {
  font-weight: 600;
}

.founders__desc {
  line-height: 2;
  font-size: 18px;
  text-align: justify;
  display: grid;
  align-items: center;
}

.large__screen_enable {
  display: none;
}

@media (max-width: 992px) {
  .founder__container {
    grid-template-columns: 350px 350px;
  }
}

@media (max-width: 850px) {
  .founders__section {
    padding: 0 20px;
  }
  .founder__container {
    grid-template-columns: 550px;
    row-gap: 30px;
  }
}

@media (max-width: 768px) {
  .founders__desc {
    line-height: 1.5;
    font-size: 16px;
  }
  .founders__section h2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) {
  .founder__container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 500px) {
  .founders__img img {
    height: 80px;
    width: 80px;
  }

  .founders__img {
    gap: 2px;
  }

  .founders__row {
    padding: 15px;
  }
}

@media (min-width: 1200px) {
  .large__screen_enable {
    display: block;
  }
}
