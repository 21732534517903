.live__streaming__container {
  padding: 20px 40px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 40px;
  column-gap: 40px;
}

.live__streaming_section h2 {
  font-size: 28px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;
}

.youtube__videos__card {
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  transition: all 0.3s;
}

.youtube__videos__card:hover {
  background-color: #fff3bf;
}

.youtube__videos__card h3 {
  text-align: center;
  font-weight: 600;
}

.youtube__videos__card p {
  text-align: justify;
}

.youtube__videos__card iframe {
  height: 240px;
  width: 100%;
}

.view__more__btn__container {
  display: grid;
  place-content: center;
  margin-bottom: 20px;
}

.view__more__btn {
  text-decoration: none;
  color: #fff;
  background-image: linear-gradient(
    108.7deg,
    rgba(221, 22, 224, 1) 11%,
    rgba(111, 22, 190, 1) 88.2%
  );
  padding: 10px 25px;
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
}

@media (max-width: 992px) {
  .live__streaming__container {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .live__streaming__container {
    grid-template-columns: 1fr;
    padding: 20px;
  }
}

@media (max-width: 500px) {
  .youtube__videos__card iframe {
    height: 200px;
  }
}

@media (max-width: 410px) {
  .youtube__videos__card iframe {
    height: 160px;
  }
}
