.hero__section img {
  width: 100%;
  height: auto;
}

.hero__section {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.aim__section {
  align-self: center;
  padding: 20px 40px;
}

.aim__section h2 {
  text-align: center;
  padding: 10px;
  font-size: 26px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.aim__desc {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.aim__desc span {
  text-align: justify;
  line-height: 1.5;
  font-size: 20px;
}

@media (max-width: 1280px) {
  .hide_aim__desc_2 {
    display: none;
  }
}

@media (max-width: 1180px) {
  .hide_aim__desc_1 {
    display: none;
  }
}

@media (max-width: 900px) {
  .hero__section {
    grid-template-columns: 1fr;
    row-gap: 10px;
    margin-bottom: 5px;
  }
}

@media (max-width: 768px) {
  .aim__section {
    padding: 20px;
  }
  .aim__section h2 {
    font-size: 24px;
  }
  .aim__desc span {
    font-size: 16px;
  }
}
