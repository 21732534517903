@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,200;0,400;0,600;1,400&family=Quicksand:wght@300;400;500&family=Roboto:ital,wght@0,100;0,400;0,700;1,400;1,700&display=swap");

/* font-family: 'Noto Serif', serif;
font-family: 'Quicksand', sans-serif;
font-family: 'Roboto', sans-serif; */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
  font-size: 16px !important;
}

p,
span {
  font-family: "Quicksand", sans-serif;
  font-size: 16px !important;
}

p,
a,
div {
  line-height: 1.5;
}

p::selection,
h1::selection,
h2::selection,
h3::selection,
h4::selection,
span::selection,
button::selection,
img::selection,
div::selection {
  background: #222;
  color: #fff;
}

/* OVERALL MEDIA QUERIES */
@media (max-width: 567px) {
  span,
  p {
    font-size: 12px !important;
  }

  a {
    font-size: 16px !important;
  }

  .aim__section,
  .events__container {
    padding: 0 20px !important;
  }

  .aim__section h2 {
    padding: 0 !important;
  }

  .aim__section h2,
  .founders__section h2,
  .members__section h2,
  .live__streaming_section h2,
  .events__section h2,
  .headline,
  .donate_container h2,
  .contact__heading h2 {
    font-size: 18px !important;
  }

  .members__section h2 {
    margin-bottom: 10px !important;
  }

  .members__container h2,
  .members__container h3 {
    font-size: 12px !important;
    margin-bottom: 0 !important;
  }

  .live__streaming_section h2 {
    margin: 20px 0 0 0 !important;
  }

  .youtube__videos__card h3 {
    font-size: 14px !important;
  }

  .event__card___content .event_headline {
    font-size: 14px !important;
    padding: 0 !important;
    font-weight: 600 !important;
  }
  .event__card___content {
    padding: 0 10px 10px 10px !important;
  }
  .event__desc {
    margin: 4px 0 10px 0 !important;
  }
  .zoomLink__btn,
  .guest_artist_card h2 {
    font-size: 12px !important;
  }
  .event__card {
    margin: 0 20px !important;
  }
  .event__card___title {
    font-size: 16px !important;
    margin-bottom: 10px !important;
  }
  .event__headline {
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }
  .donate__bank_details_container h4,
  .form__container h4 {
    font-size: 14px !important;
  }
  .our__donors .card h3 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .form__container {
    padding: 20px !important;
  }

  .form__container label,
  .form__container input,
  .form__container textarea {
    font-size: 12px !important;
  }

  .icon .location,
  .icon .phone,
  .icon .emailicon {
    height: auto !important;
  }

  .icon {
    padding-bottom: 0 !important;
  }
}
