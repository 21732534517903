.donate_container {
  padding: 20px 40px 40px 40px;
}

.donate_container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 26px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.ourDonors__heading {
  padding-top: 20px;
}

.donate__details_container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
}

.donate__bank_details_container {
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 300px;
  gap: 10px;
  justify-self: end;
}

.donate__bank_details_container span {
  font-weight: 600;
}

.donate__Qr_image_container {
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 20px;
  width: 300px;
  text-align: center;
}

.donate__Qr_image_container img {
  height: 300px;
  width: 200px;
}

.our__donors {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

@media (max-width: 1050px) {
  .our__donors {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 800px) {
  .our__donors {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .donate__details_container {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 20px;
  }
  .donate__Qr_image_container,
  .donate__bank_details_container {
    justify-self: center;
  }
  .donate_container {
    padding: 20px;
  }
}

@media (max-width: 576px) {
  .our__donors {
    grid-template-columns: 1fr;
  }
}
