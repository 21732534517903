.contact__heading {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.contact__heading h2 {
  font-size: 26px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
}

.contact__form {
  display: flex;
  background-color: rgb(235, 236, 241);
  width: 80vw;
  margin: auto;
  border-radius: 7px 0 0 7px;
}

.form__container {
  width: 60%;
  height: 100%;
  display: grid;
  padding: 30px;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 20px;
}

.form__container h4,
.form__container .email,
.form__container .message {
  grid-column: 1/3;
}

.form__container input,
.form__container textarea {
  width: 100%;
  border: none;
  -webkit-box-shadow: 8px 8px 8px -3px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 8px 8px 8px -3px rgba(122, 104, 104, 0.06);
  box-shadow: 8px 8px 8px -3px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-top: 5px;
}

.form__container textarea {
  height: 75px;
  padding-left: 10px;
  padding-top: 10px;
}

.form__container input {
  height: 30px;
  padding-left: 10px;
}

.image {
  width: 40%;
  height: 100%;
}

iframe {
  width: 100%;
  height: 400px;
}

.form__container .submit--here {
  width: 96px;
  padding-left: 0;
  background-image: linear-gradient(
    108.7deg,
    rgba(221, 22, 224, 1) 11%,
    rgba(111, 22, 190, 1) 88.2%
  );
  color: white;
  cursor: pointer;
  font-size: 16px;
}

.icon {
  display: flex;
  width: 80vw;
  justify-content: space-around;
  align-items: center;
  margin: 42px auto;
  font-family: "Roboto", sans-serif;
  gap: 50px;
}

.icon .box i {
  color: white;
}

.icon .box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  width: 33px;
  border-radius: 50%;
  /* background-color: rgb(16, 16, 167); */
  background-image: linear-gradient(
    108.7deg,
    rgba(221, 22, 224, 1) 11%,
    rgba(111, 22, 190, 1) 88.2%
  );
}

.location,
.phone,
.emailicon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 350px;
  height: 200px;
  box-shadow: 0 1.2rem 3.2rem rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 20px;
}

.contact--info {
  font-size: 18px;
  font-weight: 600;
}
.details {
  text-align: center;
  line-height: 20px;
  letter-spacing: 0.5px;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}
.icon a {
  text-decoration: none;
}

.emailicon a {
  color: black;
}

@media only screen and (max-width: 600px) {
  .contact__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .form__container {
    width: 100%;
  }

  .box {
    grid-column: 1/3;
  }

  .image {
    width: 100%;
  }

  .icon {
    flex-direction: column;
    gap: 20px;

    padding-bottom: 20px;
  }

  .location,
  .phone,
  .emailicon {
    height: 180px;
    line-height: 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
  .contact__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .form__container {
    width: 100%;
  }

  .box {
    grid-column: 1/3;
  }

  .image {
    width: 100%;
  }

  .icon {
    flex-direction: column;
    gap: 20px;

    padding-bottom: 20px;
  }

  .location,
  .phone,
  .emailicon {
    height: 180px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .icon .box {
    height: 33px;
    width: 33px;
  }

  .contact--info {
    font-size: 15px;
  }

  .details {
    font-size: 12px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1035px) {
  .location {
    gap: 13px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 892px) {
  .location {
    gap: 5px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 365px) {
  .location,
  .phone,
  .emailicon {
    width: 90%;
    height: 220px;
  }
}
