.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
  height: 10vh;
  background: #222;
  color: #fff;
}

.logo_container img {
  height: 68px;
}

.nav ul {
  display: flex;
  gap: 20px;
}

.nav ul li {
  list-style: none;
}

.nav ul li a {
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.3s;
  font-family: "Quicksand", sans-serif;
}

.nav ul li a:hover {
  color: red;
}

.burger {
  display: flex;
  flex-direction: column;
  gap: 7px;
  display: none;
}

.burger .line {
  width: 30px;
  height: 3px;
  background-color: #fff;
}

@media (max-width: 1050px) {
  .header {
    padding: 0 20px;
  }
  .nav ul {
    gap: 10px;
  }
}

@media (max-width: 900px) {
  .header {
    position: relative;
  }
  .burger {
    display: flex;
    transition: all 0.3s;
  }

  .nav ul {
    gap: 20px;
  }

  .header .nav__links {
    position: absolute;
    top: 10vh;
    left: 0;
    height: 90vh;
    width: 100%;
    background-color: #343a40;
    flex-direction: column;
    text-align: center;
    padding-top: 20px;
    transform: translateX(-100%);
    transition: all 0.3s;
    z-index: 10;
  }

  .header .navBarActive {
    transform: translateX(0);
  }

  .toggle .line {
    transition: all 0.3s;
  }

  .toggle .line__1 {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .toggle .line__2 {
    opacity: 0;
  }

  .toggle .line__3 {
    transform: rotate(45deg) translate(-8px, -9px);
  }

  .header .nav__links li:hover {
    background-color: #343a40;
  }

  .header .nav__links a {
    border-radius: 20px;
    padding: 0.2rem 1rem;
    transition: all 0.3s;
  }
  .header .nav__links a:hover {
    background-color: #1098ad;
  }
}
